import { Button, Card, Collapse, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";
import { useState } from "react";
import { Logs } from "./components/Logs";

// import jsonData from "../data.json";

const { Panel } = Collapse;

export function Home() {
  const [json, setJson] = useState(null);

  const onFinish = (values: any) => {
    try {
      const _json = JSON.parse(values.json);
      setJson(_json);
    } catch (e) {
      alert("Wrong json format");
    }
  };

  console.log("json", json);

  return (
    <div>
      {!json && (
        <Card title="Sentry json">
          <Form
            onFinish={onFinish}
            // initialValues={{ json: JSON.stringify(jsonData) }}
          >
            <FormItem label="json" name="json">
              <Input.TextArea rows={20}/>
            </FormItem>

            <Form.Item style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}

      {json && <Logs jsonData={json} />}
    </div>
  );
}
