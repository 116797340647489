import { Card, Collapse, CollapseProps, Empty } from "antd";
import { IManifest, getStatusName } from "../logs.helper";
import { Order } from "./Order";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import { IOrder } from "../../../entities/logs/logs.mapper";

interface IProps {
  manifest: IManifest;
}

const getPhotosWidget = (order: IOrder) => {
  return (
    <span>
      <span>Photos: {order.photos.length} </span>
      {order.allPhotosWereSynced ? (
        <CheckCircleFilled style={{ color: "green" }} />
      ) : (
        <WarningFilled style={{ color: "red" }} />
      )}
    </span>
  );
};

const getExtra = (order: IOrder) => {
  const remoteStatus = getStatusName(order.lastSyncedStatusId);
  const isSynced = order.OrderStatus === remoteStatus;
  const isCompleted =
    isSynced && (remoteStatus === "Picked up" || remoteStatus === "Delivered");

  return (
    <>
      {order.photos.length > 0 && <span>{getPhotosWidget(order)}</span>}
      <span>
        {order.isSyncing && (
          <span>
            Is Syncing now <WarningFilled style={{ color: "red" }} />
          </span>
        )}
      </span>{" "}
      <span>
        {isSynced && "Synchronized"}
        {!isSynced && (
          <span>
            Is not synced <WarningFilled style={{ color: "red" }} />
          </span>
        )}
      </span>
      <span>{" & "}</span>
      <span>
        {isCompleted && "Completed"}
        {!isCompleted && (
          <span>
            Not Completed <WarningFilled style={{ color: "orange" }} />
          </span>
        )}
      </span>
      <span> </span>
      {isCompleted && isSynced && (
        <CheckCircleFilled style={{ color: "green" }} />
      )}
    </>
  );
};

export function Manifest(props: IProps) {
  const { manifest } = props;

  const items: CollapseProps["items"] = manifest.orders.map((item, index) => ({
    key: index,
    label: `${item.OrderType}: ${item.OrderId} ${
      item.CustomerReference ? "(" + item.CustomerReference + ")" : ""
    }`,
    children: <Order order={item} />,
    extra: getExtra(item),
  }));

  return (
    <div>
      <Collapse items={items}></Collapse>
      {manifest.orders.length === 0 && <Empty />}
    </div>
  );
}
