import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import { dateToTimeString } from "../../../common/utils/common.utils";
import { IOrder } from "../../../entities/logs/logs.mapper";
import { getStatusName } from "../logs.helper";

interface IProps {
  order: IOrder;
}

const getHighlightedText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));
  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: "bold" }
              : {}
          }
        >
          {part}
        </span>
      ))}{" "}
    </span>
  );
};

const getMessage = (message: string, orderId: number) => {
  return getHighlightedText(message, String(orderId));
};

export function Order(props: IProps) {
  const { order } = props;

  const remoteStatus = getStatusName(order.lastSyncedStatusId);

  return (
    <div>
      <Row gutter={14} style={{ paddingBottom: "12px" }}>
        <Col>
          <div>Last sync at: {dateToTimeString(order.lastSyncedDate)}</div>
          {order.lastSyncedError && (
            <div style={{ color: "red" }}>Error: {order.lastSyncedError}</div>
          )}
          <div>App status: {order.OrderStatus}</div>
          <div>Server Status: {remoteStatus}</div>
        </Col>

        <Col>
          {order.DeliveryStartTime && (
            <div>
              DeliveryStartTime: {dateToTimeString(order.DeliveryStartTime)}
            </div>
          )}
          {order.DeliveryArrived && (
            <div>
              DeliveryArrived: {dateToTimeString(order.DeliveryArrived)}
            </div>
          )}
          {order.DeliveryEndTime && (
            <div>
              DeliveryEndTime: {dateToTimeString(order.DeliveryEndTime)}
            </div>
          )}
          {order.PickupStartTime && (
            <div>
              PickupStartTime: {dateToTimeString(order.PickupStartTime)}
            </div>
          )}
          {order.PickupArrived && (
            <div>
              PickupArrived: {dateToTimeString(order.PickupArrived)}
            </div>
          )}
          {order.PickupEndTime && (
            <div>PickupEndTime: {dateToTimeString(order.PickupEndTime)}</div>
          )}
          {order.ArrivalTime && (
            <div>Exp. ArrivalTime: {String(order.ArrivalTime)}</div>
          )}
        </Col>
      </Row>

      <div style={{ paddingBottom: "12px" }}>
        <span>
          <b>Photos (Total: {order.photos.length}): </b>
        </span>

        {order.photoObjects.map((item) => (
          <div key={item.id}>
            <span>{item.id}</span>

            {item.isSyncing && (
              <span>
                {" "}
                Is still Syncing <WarningFilled style={{ color: "red" }} />
              </span>
            )}

            {!item.isSynced && (
              <span>
                {" "}
                Is not uploaded <WarningFilled style={{ color: "red" }} />
              </span>
            )}

            {!item.isSyncing && item.isSynced && (
              <span>
                {" "}
                <CheckCircleFilled style={{ color: "green" }} />
              </span>
            )}

            <span> {item.imageType} ({dateToTimeString(item.lastSyncedDate)})</span> <span style={{color: 'gray'}}>{item.uri}</span>
          </div>
        ))}
      </div>

      {order.logs
        .sort((a, b) => a.time.getTime() - b.time.getTime())
        .map((message) => (
          <div key={JSON.stringify(message)} style={{ color: "darkgray" }}>
            <span>{dateToTimeString(message.time)}: </span>
            <span>{getMessage(message.message, order.OrderId)}</span>
          </div>
        ))}
    </div>
  );
}
