import { Affix, Card, Col, Row } from "antd";
import { dateToTimeString } from "../../../common/utils/common.utils";
import {
  ILogMessage,
  IOrder,
  IPhoto,
  mapFromGeneralInfo,
  mapFromLogs,
  mapFromOrders,
  mapFromPhotos,
} from "../../../entities/logs/logs.mapper";
import {
  getExtraItemData,
  groupOrdersByManifest,
  populateLogs,
  populatePhotos,
} from "../logs.helper";
import { Manifest } from "./Manifest";

interface IProps {
  jsonData: any;
}

export function Logs(props: IProps) {
  let orders: IOrder[] = [];
  let photos: IPhoto[] = [];
  let logs: ILogMessage[] = [];

  const generalInfo = mapFromGeneralInfo(props.jsonData);

  console.log(props.jsonData?.extra)

  const ordersData = getExtraItemData(props.jsonData?.extra, "orders");
  if (ordersData) {
    orders = mapFromOrders(ordersData, generalInfo.appVesion);
  }

  const photosData = getExtraItemData(props.jsonData?.extra, "photos");
  if (photosData) {
    photos = mapFromPhotos(photosData, generalInfo.appVesion);
    orders = populatePhotos(orders, photos);
  }

  const logsData = getExtraItemData(props.jsonData?.extra, "log");
  if (logsData) {
    logs = mapFromLogs(logsData);
    orders = populateLogs(orders, logs);
  }

  const manifests = groupOrdersByManifest(orders);

  return (
    <Row gutter={12}>
      <Col span={20}>
        {manifests.map((manifest, index) => (
          <Card key={index} title={manifest.manifestId} style={{marginBottom: '20px'}}>
            <Manifest  manifest={manifest} />
          </Card>
        ))}
      </Col>
      <Col span={4}>
        <Affix offsetTop={20}>
          <div>
            <div>App Version: {generalInfo.appVesion}</div>
            <div>Sentry Time: {dateToTimeString(generalInfo.sentryDate)}</div>
            <div>
              Last Syncronizer try: {dateToTimeString(generalInfo.sentryDate)}
            </div>

            <div>
              {generalInfo.requests.map((item, index) => (
                <div
                  key={index}
                  style={{
                    fontSize: 10,
                    paddingBottom: "5px",
                    color: item.status === 200 ? "darkgray" : "red",
                  }}
                >
                  {dateToTimeString(item.time)}: <br />[{item.status}]{" "}
                  {item.url}
                </div>
              ))}
            </div>
          </div>
        </Affix>
      </Col>
    </Row>
  );
}
