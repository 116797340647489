import { ILogMessage, IOrder, IPhoto } from "../../entities/logs/logs.mapper";

export interface IManifest {
  manifestId: number;
  orders: IOrder[];
}

export const groupOrdersByManifest = (orders: IOrder[]): IManifest[] => {
  const manifests: IManifest[] = [];

  orders.forEach((order: IOrder) => {
    const manifest = manifests.find((m) => m.manifestId === order.manifestId);

    if (manifest) {
      manifest.orders.push(order);
      return;
    }

    manifests.push({
      manifestId: order.manifestId,
      orders: [order],
    });
  });

  return manifests;
};

export const populatePhotos = (
  orders: IOrder[],
  photos: IPhoto[]
): IOrder[] => {
  return orders.map((order: IOrder) => {
    const orderPhotos: IPhoto[] = [];

    let allPhotosWereSynced = true;
    order.photos.forEach((photo) => {
      const photoObj = photos.find((item) => item.id === photo);
      if (photoObj) orderPhotos.push(photoObj);
      if (allPhotosWereSynced && !photoObj?.isSynced) allPhotosWereSynced = false
    });

    order.photoObjects = orderPhotos;
    order.allPhotosWereSynced = allPhotosWereSynced;
    return order;
  });
};

export enum EOrderStatusesId {
  Pending = 0,
  EnrouteForDelivery = 1,
  Delivered = 2,
  EnrouteForPickup = 3,
  PickedUp = 4,
  Cancelled = 5,
}

export const getStatusName = (id: number) => {
  if (id === EOrderStatusesId.Cancelled) return "Cancelled";
  if (id === EOrderStatusesId.PickedUp) return "Picked up";
  if (id === EOrderStatusesId.Delivered) return "Delivered";
  if (id === EOrderStatusesId.Pending) return "Pending";
  if (id === EOrderStatusesId.EnrouteForDelivery) return "In progress";
  if (id === EOrderStatusesId.EnrouteForPickup) return "In progress";

  return "-";
};

export const populateLogs = (orders: IOrder[], logs: ILogMessage[]) => {
  let usedLogMessages: ILogMessage[] = [];

  return orders.map((order) => {
    const orderLogs = logs.filter((log) => {
      if (log.message.includes(String(order.OrderId))) return true;

      order.photos.forEach((photo) => {
        if (log.message.includes(String(photo))) return true;
      });

      if (log.message.includes("Resetting")) return true;
      if (log.message.includes("ServerSynchronizer")) return true;
      if (log.message.includes("Logout")) return true;
      if (log.message.includes("Login")) return true;
      if (log.message.includes("storage")) return true;
      if (log.message.includes("Connection")) return true;
    });

    usedLogMessages = usedLogMessages.concat(orderLogs);

    order.logs = orderLogs;

    return order;
  });
};

export const getExtraItemData = (extraData: any, key: string) => {
  if (!extraData) {
    return undefined;
  }

  if (extraData.hasOwnProperty(key)) {
    return extraData[key];
  }

  const logKeys = Object.keys(extraData).filter((item) => item.includes(key));

  if (logKeys.length) {
    const concatenatedLogs: Object[] = [];

    logKeys.forEach((logPart) => {
      concatenatedLogs.push(...extraData[logPart]);
    });

    return concatenatedLogs;
  }

  return undefined;
};
