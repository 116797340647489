import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface SentryDateState {
  json?: Object
}

const initialState: SentryDateState = {
    json: undefined,
}

export const setryDataSlice = createSlice({
  name: 'setryData',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Object>)=>{
        state.json = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { set } = setryDataSlice.actions

export default setryDataSlice.reducer