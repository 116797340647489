import gte from "semver/functions/gte";

export interface ILogMessage {
  message: string;
  time: Date;
}

export interface IPhoto {
  feId: string;
  id: number;
  imageType: string;
  lastSyncedDate: Date;
  isSynced: boolean;
  isSyncing: boolean;
  orderTypeName: string;
  syncStatus: string;
  uri: string;
}

interface IPhotoLink {
  id: string;
}

type TPhotoId = string | number;

export interface IOrder {
  ArrivalTime: Date;
  CustomerReference: string;
  DeliveryEndTime?: Date;
  DeliveryStartTime: Date;
  DeliveryArrived: Date;
  OrderId: number;
  OrderStatus: string;
  OrderStatusId: number;
  OrderType: string;
  PickupEndTime: Date;
  PickupStartTime: Date;
  PickupArrived: Date;
  UploadedPictures: 2;
  VehicleId: number;
  isSyncing: boolean;
  lastSyncedDate: Date;
  lastSyncedError: string;
  lastSyncedStatusId: number;
  manifestId: number;
  photos: TPhotoId[];
  photoObjects: IPhoto[];
  userId: number;
  logs: ILogMessage[];
  allPhotosWereSynced?: boolean;
}

const mapBETime = (dateString: string) => {
  if (dateString && dateString !== "[undefined]") {
    dateString = dateString.replace(" ", "T");
    return new Date(dateString);
  }

  return undefined;
};

const mapBEUndefind = (str: string) => {
  if (str === "[undefined]") {
    return undefined;
  }

  return str;
};

export const mapFromOrders = (
  data: Array<any>,
  appVersion: string
): IOrder[] => {
  if (gte(appVersion, "4.1.0")) {
    return data.map((item: any) => {


      return {
        ArrivalTime: item.arrivalTime, // То когда заказ ожидается к заказу
        CustomerReference: item.customerReference,
        DeliveryEndTime: mapBETime(item.deliveredTime), // Finish
        DeliveryStartTime: mapBETime(item.deliveryEnrouteTime), // Started
        DeliveryArrived: mapBETime(item.deliveryStartedAtTime), // Arrived
        OrderId: item.orderId,
        OrderStatus: item.statusName,
        OrderStatusId: item.status,
        OrderType: item.typeName,
        PickupEndTime: mapBETime(item.pickedUpTime),
        PickupStartTime: mapBETime(item.pickupEnrouteTime),
        PickupArrived: mapBETime(item.pickupStartedAtTime),
        UploadedPictures: item.localPhotos,
        VehicleId: item.vehicleId,
        isSyncing: item.isSyncing,
        lastSyncedDate: new Date(item.lastSyncedDate),
        lastSyncedError: mapBEUndefind(item.lastSyncedError),
        lastSyncedStatusId: item.lastSyncedStatusId,
        manifestId: item.manifestId,
        photos: mapBEUndefind(item.photos) || [],
        userId: item.userId,
        logs: [],
        photoObjects: [],
      } as IOrder;
    });
  }

  return data.map((item: any) => {
    let photos:IPhotoLink[] = []

    if(Array.isArray(item.photos)){
      photos = item.photos;
    }

    return {
      ArrivalTime: item.ArrivalTime,
      CustomerReference: item.CustomerReference,
      DeliveryEndTime: mapBETime(item.DeliveryEndTime),
      DeliveryStartTime: mapBETime(item.DeliveryStartTime),
      DeliveryArrived: mapBETime(item.DeliveryStartedAtTime),
      OrderId: item.OrderId,
      OrderStatus: item.OrderStatus,
      OrderStatusId: item.OrderStatusId,
      OrderType: item.OrderType,
      PickupEndTime: mapBETime(item.PickupEndTime),
      PickupStartTime: mapBETime(item.PickupStartTime),
      PickupArrived: mapBETime(item.PickupStartedAtTime),
      UploadedPictures: item.UploadedPictures,
      VehicleId: item.VehicleId,
      isSyncing: item.isSyncing,
      lastSyncedDate: new Date(item.lastSyncedDate),
      lastSyncedError: mapBEUndefind(item.lastSyncedError),
      lastSyncedStatusId: item.lastSyncedStatusId,
      manifestId: item.manifestId,
      photos: photos.map(item => item.id),
      userId: item.userId,
      logs: [],
      photoObjects: [],
    } as IOrder;
  });
};

export const mapFromPhotos = (data: Array<any>, appVersion: string): IPhoto[] => {
  if (gte(appVersion, "4.1.0")) {
    return data.map((item: any) => {
      return {
        feId: item.feId,
        id: item.id,
        imageType: item.imageType,
        lastSyncedDate: new Date(item.lastSyncedDate),
        isSynced: item.isSynced,
        isSyncing: item.isSyncing,
        syncStatus: item.syncStatus,
        orderTypeName: item.orderTypeName,
        uri: item.uri,
      } as IPhoto;
    });
  }

  return data.map((item: any) => {
    return {
      feId: '-',
      id: item.id,
      imageType: 'photo',
      lastSyncedDate: new Date(item.lastSyncedDate),
      isSynced: item.uploaded,
      isSyncing: item.isSyncing,
      syncStatus: item.syncStatus,
      orderTypeName: '-',
      uri: item.uri,
    } as IPhoto;
  });
};

export const mapFromLogs = (data: Array<any>): ILogMessage[] => {
  return data.map((item: any) => {
    return {
      message: item.message,
      time: new Date(item.time),
    } as ILogMessage;
  });
};

export const mapFromBreadcrumps = (data: Array<any>) => {
  if (!data) return [];

  return data
    .filter((item) => {
      return (
        item.type === "http" && item?.data?.url?.includes("live.solbox.it")
      );
    })
    .map((item: any) => {
      return {
        time: new Date(item?.timestamp * 1000),
        status: item?.data?.status_code,
        url: item?.data?.url.replace("https://live.solbox.it", ""),
      };
    });
};

export const mapFromGeneralInfo = (data: any) => {
  return {
    sentryDate: new Date(data.datetime),
    requests: mapFromBreadcrumps(data?.breadcrumbs?.values),
    lastSynchronizerTry: new Date(data?.extra?.lastSynchronizerTry),
    appVesion: data?.contexts?.app?.app_version,
  };
};
